import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISplitPanelContainerState } from "./interfaces";

const initialState: ISplitPanelContainerState = {
  title: "",
  showPanel: false,
};

export const splitPanelContainerSlice = createSlice({
  name: "splitPanelContainer",
  initialState,
  reducers: {
    updateSplitPanelContainer: (
      state,
      action: PayloadAction<ISplitPanelContainerState>
    ) => {
      state.title = action.payload.title;
      state.showPanel = action.payload.showPanel;
    },
  },
});

export const { updateSplitPanelContainer } = splitPanelContainerSlice.actions;

export default splitPanelContainerSlice.reducer;
