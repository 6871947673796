import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: {
  formSubmitted: boolean;
  selectedApttusExceptions: any[];
} = {
  selectedApttusExceptions: [],
  formSubmitted: false,
};

export const apttusExceptionsSlice = createSlice({
  name: "ApttusExceptionsState",
  initialState,
  reducers: {
    updateSelectedApttusExceptions: (
      state: { selectedApttusExceptions: any[] },
      action: PayloadAction<any[]>
    ) => {
      state.selectedApttusExceptions = action.payload;
    },
    updateFormSubmitted: (
      state: { formSubmitted: boolean },
      action: PayloadAction<boolean>
    ) => {
      state.formSubmitted = action.payload;
    },
  },
});

export const { updateSelectedApttusExceptions } = apttusExceptionsSlice.actions;

export const { updateFormSubmitted } = apttusExceptionsSlice.actions;

export default apttusExceptionsSlice.reducer;
