import { useAppDispatch, useAppSelector } from "app/store";
import { GLTransaction } from "./interfaces";
import { updateSelectedApttusExceptions, updateFormSubmitted } from "./slice";

export const useApttusExceptions = () => {
  const dispatch = useAppDispatch();
  const apptusExceptions = useAppSelector((state) => state.apttusExceptions);
  return {
    selectedApttusExceptions: apptusExceptions.selectedApttusExceptions,
    formSubmitted: apptusExceptions.formSubmitted,
    updateSelectedApttusExceptions: (payload: GLTransaction[]) =>
      dispatch(updateSelectedApttusExceptions(payload)),
    updateFormSubmitted: (payload: boolean) =>
      dispatch(updateFormSubmitted(payload)),
  };
};
