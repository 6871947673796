import { useAppDispatch, useAppSelector } from "app/store";
import { Period } from "./interfaces";
import { periodStateUpdate } from "./slice";

export const usePeriod = () => {
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.period);

  return {
    period: selector,
    updatePeriod: (payload: Period) => dispatch(periodStateUpdate(payload)),
  };
};
