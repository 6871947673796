import React from "react";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { Formik } from "formik";
import Form from "@amzn/awsui-components-react/polaris/form";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Box from "@amzn/awsui-components-react/polaris/box";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Input from "@amzn/awsui-components-react/polaris/input";
import Select, {
  SelectProps,
} from "@amzn/awsui-components-react/polaris/select";
import { TAGS } from "services/main";
import { useApiUtils } from "hooks/useApiUtils";
import { usePeriod } from "components/period-selector/hooks";
import { updateEffectiveDate } from "components/apttus-agreement-actions/utils";
import { useAccountingRegion } from "components/accounting-region-selector/hooks";
import { getErrorMessage } from "components/api-indicator/utils";
import { useNotifications } from "features/notifications";
import { useSplitPanelContainer } from "features/split-panel-container";
import { useApttusExceptions } from "pages/apttus-exceptions";
import { LAZY_LOAD_BATCH_DETAILS_KEY } from "pages/approval-batch-details";
import {
  createLabelValueObject,
  testRegex,
  getSelectBoxStatusType,
} from "utils/utils";
import {
  useGetAgreementNumberDetailsQuery,
  useGetCategorySubcategoryDataQuery,
  useSendApttusExceptionsForApprovalMutation,
} from "./endpoints";
import {
  AgreementNumberMap,
  ApttusAgreementActionDetails,
  GetAgreementNumberDetailsRequest,
  IApttusAgreementActionsProps,
  ResolutionMetadata,
} from "./interfaces";
import {
  NOTIFICATION_TIMEOUT_IN_SEC,
  PaymentTerms,
  AgreementNumberRegex,
  DefaultAgreementDetails,
  TerminatedStatus,
} from "./constants";
import { getCategories, getSubcategories } from "./utils";
import ReprocessBatchSubmissionModal from "components/reprocess-batch-submission-modal";

const ApttusAgreementActions: React.FC<IApttusAgreementActionsProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { period } = usePeriod();
  const { region } = useAccountingRegion();
  const { invalidateTags } = useApiUtils();
  const { selectedApttusExceptions, updateFormSubmitted } =
    useApttusExceptions();
  const { addNotification } = useNotifications();
  const { updateSplitPanel } = useSplitPanelContainer();
  const [skip, setSkip] = React.useState(true);
  const [skipCategorySubcategoryDataApi, setSkipCategorySubcategoryDataApi] =
    React.useState<boolean>(true);
  const [isFormCleared, setIsFormCleared] = React.useState(true);
  const [isAgreementNumber, setIsAgreementNumber] = React.useState(false);
  const [isAgreementNumberModified, setIsAgreementNumberModified] =
    React.useState(true);
  const [categories, setCategories] = React.useState<SelectProps.Options>([]);
  const [subCategories, setSubCategories] = React.useState<SelectProps.Options>(
    []
  );
  const [showModal, setShowModal] = React.useState(false);
  const [initialValues, setInitialValues] =
    React.useState<ApttusAgreementActionDetails>(DefaultAgreementDetails);
  const [agreementNumberDetailsRequest, setAgreementNumberDetailsRequest] =
    React.useState<GetAgreementNumberDetailsRequest>({
      agreementNumber: DefaultAgreementDetails.agreementNumber,
      effectiveDate: DefaultAgreementDetails.effectiveDate,
    });
  const categorySubcategoryDataResponse = useGetCategorySubcategoryDataQuery(
    null,
    {
      skip: skipCategorySubcategoryDataApi,
    }
  );
  const categorySubcategoryObject =
    categorySubcategoryDataResponse.data?.categorySubcategoryMappings ?? {};
  const [trigger, { isSuccess, data, error, isError, isLoading }] =
    useSendApttusExceptionsForApprovalMutation();
  const response = useGetAgreementNumberDetailsQuery(
    agreementNumberDetailsRequest,
    {
      skip,
    }
  );

  const agreementNumber: AgreementNumberMap | undefined =
    response?.data?.apttusAgreementRecord;

  const triggerSendApttusExceptionsForApproval = (
    resolutionMetaData: ResolutionMetadata,
    requesterJustification: string
  ) => {
    trigger({
      accountingMonth: period.value,
      accountingRegion: region.value,
      resolutionMetadata: resolutionMetaData,
      requesterJustification,
      apttusExceptionReprocessList: selectedApttusExceptions.map(
        (exception: any) => {
          return {
            groupId: exception.groupId,
            version: exception.version,
          };
        }
      ),
    });
  };

  const submitBatch = (message: string) => {
    if (isAgreementNumber) {
      setShowModal(false);
      triggerSendApttusExceptionsForApproval(
        {
          type: "APTTUS_EXCEPTION_REPROCESS_BY_AGREEMENT_NUMBER_MAPPING",
          paymentTerms: null,
          incomeType: null,
          incomeSubType: null,
          agreementNumber: initialValues?.agreementNumber,
        },
        message
      );
    } else if (
      !!initialValues?.selectedCategory &&
      !!initialValues?.selectedSubcategory &&
      !!initialValues?.selectedPaymentTerm
    ) {
      setShowModal(false);
      triggerSendApttusExceptionsForApproval(
        {
          type: "APTTUS_EXCEPTION_REPROCESS_BY_MANUAL_MAPPING",
          paymentTerms: initialValues?.selectedPaymentTerm.value,
          incomeType: initialValues?.selectedCategory.value,
          incomeSubType: initialValues?.selectedSubcategory.value,
          agreementNumber: null,
        },
        message
      );
    }
  };

  const clearValues = (setFieldValue: Function) => {
    setSkip(true);
    setTimeout(() => {
      invalidateTags([TAGS.Apttus_Agreement_Action]);
    }, 0);
    setFieldValue(
      "inputAgreementNumber",
      DefaultAgreementDetails.inputAgreementNumber
    );
    setFieldValue(
      "selectedAgreementNumber",
      DefaultAgreementDetails.selectedAgreementNumber
    );
    setFieldValue("agreementName", DefaultAgreementDetails.agreementName);
    setFieldValue("agreementNumber", DefaultAgreementDetails.agreementNumber);
    setFieldValue("effectiveDate", DefaultAgreementDetails.effectiveDate);
    setFieldValue("terminationDate", DefaultAgreementDetails.terminationDate);
    setFieldValue("status", DefaultAgreementDetails.status);
    setFieldValue(
      "selectedPaymentTerm",
      DefaultAgreementDetails.selectedPaymentTerm
    );
    setFieldValue("selectedCategory", DefaultAgreementDetails.selectedCategory);
    setFieldValue(
      "selectedSubcategory",
      DefaultAgreementDetails.selectedSubcategory
    );
    setIsAgreementNumber(false);
    setIsFormCleared(true);
    setIsAgreementNumberModified(true);
  };

  const validateAgreementDetails = (values: ApttusAgreementActionDetails) => {
    if (!isAgreementNumberModified) {
      return;
    }
    setIsFormCleared(true);
    setIsAgreementNumber(true);
    setInitialValues({
      selectedAgreementNumber: DefaultAgreementDetails.selectedAgreementNumber,
      agreementName: DefaultAgreementDetails.agreementName,
      agreementNumber: DefaultAgreementDetails.agreementNumber,
      effectiveDate: DefaultAgreementDetails.effectiveDate,
      terminationDate: DefaultAgreementDetails.terminationDate,
      status: DefaultAgreementDetails.status,
      selectedCategory: DefaultAgreementDetails.selectedCategory,
      selectedSubcategory: DefaultAgreementDetails.selectedSubcategory,
      selectedPaymentTerm: DefaultAgreementDetails.selectedPaymentTerm,
      inputAgreementNumber: values.inputAgreementNumber,
    });
    setIsAgreementNumberModified(false);
    if (testRegex(AgreementNumberRegex, values.inputAgreementNumber)) {
      setAgreementNumberDetailsRequest({
        effectiveDate: updateEffectiveDate(period.value as string) || "",
        agreementNumber: values.inputAgreementNumber,
      });
      setSkip(false);
    }
  };

  const isSubmitButtonDisabled = (values: ApttusAgreementActionDetails) => {
    return (
      values.status === TerminatedStatus ||
      !values.selectedPaymentTerm ||
      !values.selectedCategory?.label ||
      !values.selectedSubcategory?.label
    );
  };

  const onCategoryChange = (
    detail: SelectProps.ChangeDetail,
    setFieldValue: Function
  ) => {
    setFieldValue("selectedCategory", detail.selectedOption);
    setSubCategories(
      getSubcategories(categorySubcategoryObject, detail.selectedOption.value)
    );
  };
  React.useEffect(() => {
    if (categorySubcategoryDataResponse.isSuccess) {
      setCategories(getCategories(categorySubcategoryObject));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(categorySubcategoryDataResponse)]);

  React.useEffect(() => {
    if (!response.isFetching && response.isSuccess && !!agreementNumber) {
      setIsFormCleared(false);
      setInitialValues((i) => {
        return {
          ...i,
          selectedAgreementNumber: createLabelValueObject(
            agreementNumber.apttusAgreementNumber as unknown as string
          ),
          agreementName: agreementNumber.name as unknown as string,
          agreementNumber:
            agreementNumber.apttusAgreementNumber as unknown as string,
          effectiveDate: agreementNumber.clmEffectiveDate as unknown as string,
          terminationDate:
            agreementNumber.apttusTerminationDate as unknown as string,
          status: agreementNumber.apttusStatus as unknown as string,
          selectedCategory: createLabelValueObject(
            agreementNumber.clmServiceAndGoodsProvidedCategory as unknown as string
          ),
          selectedSubcategory: createLabelValueObject(
            agreementNumber.clmServiceAndGoodsProvided as unknown as string
          ),
          selectedPaymentTerm: createLabelValueObject(
            agreementNumber.clmPaymentTerms as unknown as string
          ),
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(agreementNumber), response.isFetching]);

  React.useEffect(() => {
    if (isSuccess && data) {
      addNotification({
        type: "success",
        content: "Batch Created Successfully",
        action: (
          <Button
            onClick={() => {
              navigate(
                `/approvals/${data.requestID}?${LAZY_LOAD_BATCH_DETAILS_KEY}=true`
              );
            }}
          >
            View batch
          </Button>
        ),
        dismissible: true,
        dismissLabel: "Dismiss message",
        timeout: NOTIFICATION_TIMEOUT_IN_SEC,
      });
      updateSplitPanel({
        title: "",
        showPanel: false,
      });
      invalidateTags([TAGS.Apttus_Exceptions]);
      updateFormSubmitted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  React.useEffect(() => {
    if (isError) {
      addNotification({
        type: "error",
        header: "Error Occured While Submitting Batch For Approval",
        content: getErrorMessage(error),
        dismissible: true,
        timeout: NOTIFICATION_TIMEOUT_IN_SEC,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        setInitialValues(values);
        if (isAgreementNumber) {
          submitBatch("");
        } else {
          setShowModal(true);
        }
      }}
      enableReinitialize={true}
    >
      {({ values, submitForm, setFieldValue }) => {
        return (
          <Form>
            {response.isError && !isAgreementNumberModified && (
              <Box margin={{ bottom: "s" }}>
                <Alert visible={true} type="error">
                  {getErrorMessage(response.error)}
                </Alert>
              </Box>
            )}
            <FormField
              label={
                <Trans>
                  components.apttus_agreement_actions.agreement_number.label
                </Trans>
              }
            >
              <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
                <Input
                  value={values.inputAgreementNumber}
                  onChange={(e) => {
                    setIsAgreementNumberModified(true);
                    setIsAgreementNumber(true);
                    setFieldValue("inputAgreementNumber", e.detail.value);
                    if (!values.agreementNumber) {
                      setFieldValue(
                        "selectedPaymentTerm",
                        DefaultAgreementDetails.selectedPaymentTerm
                      );
                      setFieldValue(
                        "selectedCategory",
                        DefaultAgreementDetails.selectedCategory
                      );
                      setFieldValue(
                        "selectedSubCategory",
                        DefaultAgreementDetails.selectedSubcategory
                      );
                    }
                  }}
                />
                <Button
                  loading={response.isFetching}
                  disabled={
                    !testRegex(
                      AgreementNumberRegex,
                      values.inputAgreementNumber
                    )
                  }
                  onClick={() => {
                    validateAgreementDetails(values);
                  }}
                  variant="primary"
                >
                  <Trans>
                    components.apttus_agreement_actions.validate_btn
                  </Trans>
                </Button>
              </Grid>
            </FormField>

            <Box
              fontSize="heading-l"
              fontWeight="bold"
              margin={{ top: "m", bottom: "m" }}
            >
              <Trans>
                components.apttus_agreement_actions.manually_enter_data.label
              </Trans>
            </Box>
            <Grid
              gridDefinition={[
                { colspan: 12 },
                { colspan: 12 },
                { colspan: 12 },
                { colspan: 12 },
                { colspan: 12 },
                { colspan: 12 },
                { colspan: 12 },
                { colspan: 12 },
              ]}
            >
              <FormField
                label={t(
                  "components.apttus_agreement_actions.agreement_name.label"
                )}
              >
                <Input value={values.agreementName} disabled={true} />
              </FormField>

              <FormField
                label={t(
                  "components.apttus_agreement_actions.agreement_line_item.label"
                )}
              >
                <Input value={values.agreementNumber} disabled={true} />
              </FormField>

              <FormField
                label={t(
                  "components.apttus_agreement_actions.effective_date.label"
                )}
              >
                <Input value={values.effectiveDate} disabled={true} />
              </FormField>

              <FormField
                label={t(
                  "components.apttus_agreement_actions.termination_date.label"
                )}
              >
                <Input value={values.terminationDate} disabled={true} />
              </FormField>

              <FormField
                label={t(
                  "components.apttus_agreement_actions.payment_terms.label"
                )}
              >
                <Select
                  disabled={isAgreementNumber}
                  options={PaymentTerms}
                  filteringType="auto"
                  onChange={(e) => {
                    setFieldValue(
                      "selectedPaymentTerm",
                      e.detail.selectedOption
                    );
                  }}
                  selectedOption={values.selectedPaymentTerm}
                ></Select>
              </FormField>

              <FormField
                label={t(
                  "components.apttus_agreement_actions.tax_category.label"
                )}
              >
                <Select
                  disabled={isAgreementNumber}
                  options={categories}
                  filteringType="auto"
                  onChange={({ detail }) => {
                    onCategoryChange(detail, setFieldValue);
                  }}
                  selectedOption={values.selectedCategory}
                  statusType={getSelectBoxStatusType(
                    categorySubcategoryDataResponse
                  )}
                  onFocus={() => {
                    setSkipCategorySubcategoryDataApi(false);
                  }}
                  loadingText={t(
                    "components.apttus_agreement_actions.tax_category.loading_text"
                  )}
                  errorText={getErrorMessage(
                    categorySubcategoryDataResponse.error
                  )}
                ></Select>
              </FormField>
              <FormField
                label={t(
                  "components.apttus_agreement_actions.tax_subcategory.label"
                )}
              >
                <Select
                  disabled={isAgreementNumber || !values.selectedCategory}
                  options={subCategories}
                  filteringType="auto"
                  onChange={(e) => {
                    setFieldValue(
                      "selectedSubcategory",
                      e.detail.selectedOption
                    );
                  }}
                  selectedOption={values.selectedSubcategory}
                ></Select>
              </FormField>
              <FormField label="Status">
                <Input value={values.status} disabled={true} />
              </FormField>
            </Grid>
            <Box margin={{ top: "xl" }} float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  disabled={
                    (isFormCleared &&
                      !isAgreementNumber &&
                      !values.selectedCategory &&
                      !values.selectedPaymentTerm) ||
                    isLoading
                  }
                  onClick={() => {
                    clearValues(setFieldValue);
                  }}
                >
                  <Trans>components.apttus_agreement_actions.clear_btn</Trans>
                </Button>
                <Button
                  loading={isLoading}
                  variant="primary"
                  disabled={isSubmitButtonDisabled(values)}
                  onClick={submitForm}
                >
                  <Trans>components.apttus_agreement_actions.submit_btn</Trans>
                </Button>
              </SpaceBetween>
            </Box>
            <ReprocessBatchSubmissionModal
              visible={showModal}
              setVisible={setShowModal}
              submitBatch={submitBatch}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default ApttusAgreementActions;
