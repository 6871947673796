import { mainApi, TAGS } from "services/main";
import {
  CategorySubcategoryDataResponse,
  GetAgreementNumberDetailsRequest,
  GetAgreementNumberDetailsResponse,
  SubmitApttusExceptionsRequest,
  SubmitApttusExceptionsResponse,
} from "./interfaces";

const extendedApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    sendApttusExceptionsForApproval: builder.mutation<
      SubmitApttusExceptionsResponse,
      SubmitApttusExceptionsRequest
    >({
      query: (request?: SubmitApttusExceptionsRequest) => ({
        url: "exceptions/approvals/apttus/submit",
        body: request,
        method: "POST",
      }),
    }),
    getAgreementNumberDetails: builder.query<
      GetAgreementNumberDetailsResponse,
      GetAgreementNumberDetailsRequest
    >({
      query: (request?: GetAgreementNumberDetailsRequest) => ({
        url: "get_apttus_agreement_record",
        params: request,
      }),
      providesTags: [TAGS.Apttus_Agreement_Action],
    }),
    getCategorySubcategoryData: builder.query<
      CategorySubcategoryDataResponse,
      null
    >({
      query: () => ({
        url: "tax-products/categories",
        method: "GET",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useSendApttusExceptionsForApprovalMutation,
  useGetAgreementNumberDetailsQuery,
  useGetCategorySubcategoryDataQuery,
} = extendedApi;
