import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { useTranslation } from "react-i18next";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout";
import styles from "./style.module.scss";
import Router from "./router";
import { store } from "./store";
import "./i18n";
import { AuthN } from "features/auth";
import Notifications from "features/notifications";
import Breadcrumbs from "features/breadcrumbs";
import { useInfo } from "features/info";
import PageHeader from "features/page-header";
import Header from "components/header";
import Footer from "components/footer";
import LeftNavigation from "components/left-navigation";
import Loader from "components/loader";
import ErrorBoundary from "components/error-boundary";
import Amplify from "components/amplify";
import { useSettings } from "features/settings";
import { useRum } from "hooks/useRum";
import SplitContainer, {
  useSplitPanelContainer,
} from "features/split-panel-container";
import { SPLIT_PANEL_DEFAULT_SIZE } from "./constants";
import AuthZ from "features/auth/AuthZ";

const App: React.FC = (): JSX.Element => {
  useRum();
  useSettings();
  useTranslation();

  const [navigationOpen, setNavigationOpen] = React.useState<boolean>(true);
  const { info, setOpenInfo } = useInfo();

  const { splitPanel } = useSplitPanelContainer();
  const [splitPanelSize, setSplitPanelSize] = React.useState(
    SPLIT_PANEL_DEFAULT_SIZE
  );
  const [splitPanelOpen, setSplitPanelOpen] = React.useState(true);
  const onSplitPanelResize = (event: any) => {
    setSplitPanelSize(event.detail.size);
  };
  const onSplitPanelToggle = (event: any) => {
    setSplitPanelOpen(event.detail.open);
  };

  return (
    <Amplify>
      <AuthN>
        <AuthZ>
          <div className={styles.awsui}>
            <ErrorBoundary>
              <Header />

              <AppLayout
                headerSelector="#header"
                stickyNotifications={true}
                notifications={<Notifications />}
                navigation={<LeftNavigation />}
                navigationOpen={navigationOpen}
                onNavigationChange={({ detail }) =>
                  setNavigationOpen(detail.open)
                }
                breadcrumbs={<Breadcrumbs />}
                contentHeader={<PageHeader />}
                content={
                  <div className="page-container">
                    <ErrorBoundary>
                      <Router />
                    </ErrorBoundary>
                  </div>
                }
                tools={info.tools}
                toolsOpen={info.toolsOpen}
                onToolsChange={({ detail }) => setOpenInfo(detail.open)}
                footerSelector="#footer"
                splitPanelSize={splitPanelSize}
                onSplitPanelToggle={onSplitPanelToggle}
                onSplitPanelResize={onSplitPanelResize}
                splitPanelOpen={splitPanelOpen}
                splitPanelPreferences={{ position: "side" }}
                splitPanel={
                  splitPanel.showPanel && <SplitContainer></SplitContainer>
                }
              />

              <Footer />
            </ErrorBoundary>
          </div>
        </AuthZ>
      </AuthN>
    </Amplify>
  );
};

const AppProvider: React.FC = (): JSX.Element => {
  return (
    <Provider store={store}>
      <Suspense fallback={<Loader loadingText="Loading..." />}>
        <App />
      </Suspense>
    </Provider>
  );
};

export default AppProvider;
