import React from "react";
import { Trans, useTranslation } from "react-i18next";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import { LOG } from "utils";
import { useNotifications } from "features/notifications";
import { IApiIndicatorProps } from "./interfaces";
import { getErrorMessage } from "./utils";
import { NOTIFICATION_TIMEOUT_IN_SEC } from "./constants";

const ApiIndicator: React.FC<IApiIndicatorProps> = ({
  isUninitialized,
  isLoading,
  isFetching,
  isError,
  isAlertError,
  error,
  addErrorToPage = true,
  showNotification = true,
}) => {
  const { t } = useTranslation();
  const title = t("components.api_indicator.title");
  const message = getErrorMessage(error);
  const { addNotification } = useNotifications();

  React.useEffect(() => {
    if (isError && showNotification) {
      addNotification({
        type: "error",
        header: title,
        content: message,
        dismissible: true,
        timeout: NOTIFICATION_TIMEOUT_IN_SEC,
      });
      LOG.error(message);
    }
  }, [isError, message]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isUninitialized && (
        <StatusIndicator type="pending">
          <Trans>{"components.api_indicator.uninitialized"}</Trans>
        </StatusIndicator>
      )}
      {isLoading && (
        <StatusIndicator type="loading">
          <Trans>{"components.api_indicator.loading"}</Trans>
        </StatusIndicator>
      )}
      {isFetching && (
        <StatusIndicator type="in-progress">
          <Trans>{"components.api_indicator.refetching"}</Trans>
        </StatusIndicator>
      )}
      {isError && isAlertError && addErrorToPage && (
        <Alert visible={true} type="error" header={message}></Alert>
      )}
      {isError && !isAlertError && addErrorToPage && (
        <StatusIndicator type="error">{message}</StatusIndicator>
      )}
    </>
  );
};

export default ApiIndicator;
