import { SideNavigationProps } from "@amzn/awsui-components-react/polaris/side-navigation";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

/**
 * Hook for handling side navigation events
 */
export const useOnFollowHandler = () => {
  const navigate = useNavigate();

  return (event: CustomEvent<SideNavigationProps.FollowDetail>) => {
    if (!event.detail.external) {
      event.preventDefault();
      navigate(event.detail.href);
    }
  };
};

export const useNavConfig = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return {
    header: {
      href: "",
      text: t("left_nav.title"),
    } as SideNavigationProps.Header,
    items: [
      { type: "link", text: t("pages.home.title"), href: "/" },
      /* PLOP_INJECT_NAV_ITEM */
      {
        type: "link",
        text: t("pages.notifications.title"),
        href: "/notifications",
      },
      {
        type: "link",
        text: t("pages.apttus_exceptions.title"),
        href: "/apttus-exceptions",
      },
      {
        type: "link",
        text: t("pages.ambiguous_exceptions.title"),
        href: "/ambiguous-exceptions",
      },
      { type: "link", text: t("pages.approvals.title"), href: "/approvals" },
      { type: "link", text: t("pages.references.title"), href: "/references" },
    ] as SideNavigationProps.Item[],
    activeHref: location.pathname,
  };
};
