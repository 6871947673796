import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SettingsResponse } from "./interfaces";

const initialState: SettingsResponse = {
  auth: undefined,
  logger: undefined,
  endpoints: {},
  stage: {},
  analytics: {},
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    settingsLoaded: (state, action: PayloadAction<SettingsResponse>) => {
      state.auth = action.payload.auth;
      state.logger = action.payload.logger;
      state.endpoints = action.payload.endpoints;
      state.stage = action.payload.stage;
      state.analytics = action.payload.analytics;
    },
  },
});

export const { settingsLoaded } = settingsSlice.actions;

export default settingsSlice.reducer;
