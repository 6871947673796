import { TagDescription } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { useAppDispatch } from "app/store";
import { mainApi } from "services/main";

export const useApiUtils = () => {
  const dispatch = useAppDispatch();
  return {
    invalidateTags: (tagsToBeInvalidated: TagDescription<string>[]) => {
      dispatch(mainApi.util.invalidateTags(tagsToBeInvalidated));
    },
  };
};
