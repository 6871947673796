import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountingRegion } from "./interfaces";

const initialState: AccountingRegion = {};

export const regionStateSlice = createSlice({
  name: "AccountingRegionState",
  initialState,
  reducers: {
    regionStateUpdate: (
      state: AccountingRegion,
      action: PayloadAction<string>
    ) => {
      state.label = action.payload;
      state.value = action.payload;
    },
  },
});

export const { regionStateUpdate } = regionStateSlice.actions;

export default regionStateSlice.reducer;
