import React, { Suspense } from "react";
import Loader from "components/loader";
import { Routes, Route } from "react-router-dom";
import Home from "pages/home";
import Error404 from "pages/error-404";
/* PLOP_IMPORT_ROUTES */
import Notifications from "pages/notifications";
import ApprovalBatchDetails from "pages/approval-batch-details";
import References from "pages/references";
import Approvals from "pages/approvals";
import AmbiguousExceptions from "pages/ambiguous-exceptions";
import ApttusExceptions from "pages/apttus-exceptions";
import { RouteGuard } from "./RouteGuard";

const Router: React.FC = (): JSX.Element => {
  return (
    <Suspense fallback={<Loader loadingText="Loading..." />}>
      <Routes>
        <Route path="" element={<RouteGuard />}>
          <Route path="" element={<Home />} />
          <Route path="apttus-exceptions" element={<ApttusExceptions />} />
          <Route
            path="ambiguous-exceptions"
            element={<AmbiguousExceptions />}
          />
          <Route path="approvals">
            <Route path="" element={<Approvals />} />
            <Route path=":batchId" element={<ApprovalBatchDetails />} />
          </Route>
          <Route path="references" element={<References />} />
        </Route>
        {/* PLOP_INJECT_ROUTES */}
        <Route path="/notifications" element={<Notifications />} />

        {/* Must be the last route */}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
