import React from "react";
import Link from "@amzn/awsui-components-react/polaris/link";
import { useOnFollowHandler } from "./hooks";
import { useTranslation } from "react-i18next";

const Info: React.FC = () => {
  const { t } = useTranslation();
  const label = t("features.info.label");
  const onFollowHandler = useOnFollowHandler();

  return (
    <Link variant="info" onFollow={onFollowHandler} data-testid="info">
      {label}
    </Link>
  );
};

export default Info;
