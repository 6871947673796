import moment from "moment";
import lodash from "lodash";
import { UseQueryStateResult } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { FILTERING_OPERATORS } from "./constants";

const DESCENDING = "desc";
const ASCENDING = "asc";
/**
 * Function to remove items from the array
 * @param array
 * @param items
 * @returns filtered array
 */
export const removeItemsFromArray = (array: any[], items: any[]) => {
  items.forEach((item: any) => {
    const index = array.indexOf(item);
    if (index > -1) {
      array.splice(index, 1);
    }
  });
  return array;
};

/**
 * Function to convert an array into an array of object
 * Such is a requirement to convert the arrays for displaying Select Boxes
 * @param objectKeys array of keys within the object like ["key1", "key2"]
 * @param array any array like ["a","b","c"]
 * @returns an array of object collated for example [{key1:a,key2:a},{key1:b,key2:b},{key1:c, key2:c}]
 */
export const convertArrayToArrayOfObject = (
  objectKeys: string[],
  array: any[]
) => {
  return array.map((item) => {
    var obj: any = {};
    objectKeys.forEach((key) => (obj[key] = item));
    return obj;
  });
};

/**
 * Function to convert text from camelCase to TitelCase
 * Example : charAt -> Char At
 * @param text
 * @returns converted text
 */
export const convertCamelCaseToTitleCase = (text: string) => {
  const result = text.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

/**
 * Function to format the date in the expected format
 * @param date
 * @param currentFormant
 * @param newFormat
 * @returns string with the new date format
 */
export const getFormattedDate = (
  date: moment.MomentInput,
  currentFormant: string,
  newFormat: string
) => {
  return moment(date, currentFormant).format(newFormat);
};
/*
 * Function To Sort the Data
 * @param records
 * @param sortingColumn
 * @param isDescending
 * @param floatSortColumns
 * @returns
 */
export const sortRecords = (
  records: any[],
  sortingColumn: string | null,
  isDescending: boolean,
  floatSortColumns: string[] = []
) => {
  let sortedRecords = records;
  if (!sortingColumn) {
    return records;
  }
  sortedRecords = lodash.orderBy(
    sortedRecords,
    floatSortColumns.includes(sortingColumn)
      ? (record) => {
          return parseFloat(record[sortingColumn]);
        }
      : sortingColumn,
    isDescending ? DESCENDING : ASCENDING
  );
  return sortedRecords;
};

/**
 * Function to get filtering properties
 *
 * @param tableColumns Columns of the Table to get Filtering Properties
 * @returns {propertyLabel, key, groupValuesLabel, operators}[]
 */
export const getPolarisTableFilteringProperties = (tableColumns: any[]) =>
  tableColumns.map((column: string) => ({
    propertyLabel: convertCamelCaseToTitleCase(column),
    key: column,
    groupValuesLabel: convertCamelCaseToTitleCase(column),
    operators: FILTERING_OPERATORS,
  }));

/**
 * Function to test weather a string is satisfying with the regex or not
 *
 * @param regex Regex
 * @param value String
 * @returns Boolean value denoting string is satisfying with the regex or not
 */
export const testRegex = (regex: RegExp, value: string) => {
  return regex.test(value);
};

/**
 * Function to create Object of Label and Value
 *
 * @param value value in the object
 * @param label label label in the object
 * @returns object
 */
export const createLabelValueObject = (value: string, label?: string) => {
  return {
    label: label ?? value,
    value: value,
  };
};

/**
 * Function to get the status type for the Polaris Select Box
 * @param response - fetchbasequery API response
 * @returns string
 */
export const getSelectBoxStatusType = (
  response: UseQueryStateResult<any, any>
) => {
  return response.isLoading
    ? "loading"
    : response.isError
    ? "error"
    : "finished";
};
