import { useAppDispatch, useAppSelector } from "app/store";
import {
  notificationAdded,
  notificationDismissed,
  notificationsCleared,
} from "./slice";
import { INotification } from "./interfaces";

export const useNotifications = () => {
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.notifications);

  return {
    notifications: selector,
    addNotification: (payload: INotification) =>
      dispatch(notificationAdded(payload)),
    dismissNotification: (payload: string | number) =>
      dispatch(notificationDismissed(payload)),
    clearNotifications: () => dispatch(notificationsCleared()),
  };
};
