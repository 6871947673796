import React from "react";
import Header from "@amzn/awsui-components-react/polaris/header";
import { usePageHeader } from "./hooks";
import Info from "features/info";

const PageHeader: React.FC = () => {
  const { pageHeader } = usePageHeader();
  const { title, description, hasInfo } = pageHeader;

  return (
    <Header
      variant="h1"
      description={description}
      info={hasInfo && <Info />}
      data-testid="page-header"
    >
      {title}
    </Header>
  );
};

export default PageHeader;
