import { mainApi } from "services/main";

const extendedApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserAuthorizedPermissions: builder.query<any, any>({
      query: () => ({
        url: `get_user_authorised_permissions`,
        method: "GET",
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetUserAuthorizedPermissionsQuery } = extendedApi;
