import React from "react";
import { useTranslation } from "react-i18next";
import TopNavigation from "@amzn/awsui-components-react/polaris/top-navigation";
import { useAppSelector } from "app/store";
import { i18nStrings, profileActions } from "./config";
import styles from "./style.module.scss";
import logo from "./logo.svg";

const Header: React.FC = () => {
  const { fullName, email } = useAppSelector((state) => state.auth.user);
  const { t } = useTranslation();

  return (
    <TopNavigation
      id="header"
      className={styles.header}
      i18nStrings={i18nStrings}
      identity={{
        href: "#",
        title: t("app_name"),
        logo: { src: logo, alt: t("app_name") },
      }}
      utilities={[
        {
          type: "button",
          text: t("components.header.report_an_issue"),
          href: "https://t.corp.amazon.com/create/templates/d9bfccbd-99db-4e00-887f-f7cb7ae9e592",
          external: true,
          externalIconAriaLabel: t(
            "components.header.report_an_issue_aria_label"
          ),
        },
        {
          type: "menu-dropdown",
          text: fullName,
          description: email,
          iconName: "user-profile",
          items: profileActions,
        },
      ]}
    />
  );
};

export default Header;
