import React from "react";
import SplitPanel from "@amzn/awsui-components-react/polaris/split-panel";
import ApttusAgreementActions from "components/apttus-agreement-actions";
import { useTranslation } from "react-i18next";
import { useSplitPanelContainer } from "./hooks";

const SplitPanelContainer: React.FC = () => {
  const { t } = useTranslation();
  const { splitPanel } = useSplitPanelContainer();
  return (
    <SplitPanel
      header={splitPanel.title}
      i18nStrings={t("features.split_panel_container.i18n_strings")}
    >
      <ApttusAgreementActions></ApttusAgreementActions>
    </SplitPanel>
  );
};

export default SplitPanelContainer;
