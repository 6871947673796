import { updateSplitPanelContainer } from "./slice";
import { useAppDispatch, useAppSelector } from "app/store";
import { ISplitPanelContainerState } from "./interfaces";

export const useSplitPanelContainer = () => {
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.splitPanel);

  return {
    splitPanel: selector,
    updateSplitPanel: (payload: ISplitPanelContainerState) =>
      dispatch(updateSplitPanelContainer(payload)),
  };
};
