import React from "react";
import { IReprocessBatchSubmissionModalProps } from "./interfaces";
import { Trans } from "react-i18next";
import { t } from "i18next";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import { EMPTY_STRING, MAXIMUM_REASON_LENGTH_SUPPORTED } from "./constants";

const ReprocessBatchSubmissionModal: React.FC<
  IReprocessBatchSubmissionModalProps
> = ({ visible, submitBatch, setVisible }) => {
  const [message, setMessage] = React.useState<string>(EMPTY_STRING);
  const closeModal = () => {
    setMessage(EMPTY_STRING);
    setVisible(false);
  };
  return (
    <Modal
      onDismiss={() => closeModal()}
      visible={visible}
      closeAriaLabel="Close modal"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => closeModal()}>
              <Trans>components.reprocess_batch_submission_modal.cancel</Trans>
            </Button>
            <Button
              variant="primary"
              onClick={() => submitBatch(message)}
              disabled={
                message.trim().length === 0 ||
                message.trim().length > MAXIMUM_REASON_LENGTH_SUPPORTED
              }
            >
              <Trans>components.reprocess_batch_submission_modal.submit</Trans>
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={t("components.reprocess_batch_submission_modal.modal_header")}
    >
      <FormField
        label={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {t("components.reprocess_batch_submission_modal.input_label")}
            <span>
              {message.trim().length}/{MAXIMUM_REASON_LENGTH_SUPPORTED}
            </span>
          </div>
        }
      >
        <Textarea
          value={message}
          invalid={message.trim().length > MAXIMUM_REASON_LENGTH_SUPPORTED}
          onChange={({ detail }) => setMessage(detail.value)}
        />
      </FormField>
    </Modal>
  );
};

export default ReprocessBatchSubmissionModal;
