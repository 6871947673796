export const NOTIFICATION_TIMEOUT_IN_SEC = 10000;

export const DEFAULT_SUBCATEGORY_MAPPING = {
  label: "-",
};

export const PaymentTerms = [
  { label: "NET 30", value: "NET 30" },
  { label: "NET 60", value: "NET 60" },
  { label: "NET 90", value: "NET 90" },
  { label: "NET 120", value: "NET 120" },
  { label: "NET 150", value: "NET 150" },
];

export const LABEL_VALUE_ARRAY = ["label", "value"];

/**
 * Regex for Agreement Line Item
 *
 * Ex - 00012345.0_01, 00032213.2_11, 38383838.9_83
 */
export const AgreementLineItemRegex = /^\d{8}.\d{1}_\d{2}/;

/**
 * Regex with 2 to 6 numbers in it for Agreement Number
 *
 * Ex - 2453, 12345, 635638
 */
export const AgreementNumberRegex = /^\d{2,6}$/;

export const TerminatedStatus = "Terminated";

export const DefaultAgreementDetails = {
  inputAgreementNumber: "",
  selectedAgreementNumber: null,
  agreementName: "",
  agreementNumber: "",
  effectiveDate: "",
  terminationDate: "",
  status: "",
  selectedCategory: null,
  selectedSubcategory: null,
  selectedPaymentTerm: null,
};
