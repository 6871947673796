import React from "react";
import { useAppDispatch } from "app/store";
import ApiIndicator from "components/api-indicator";
import { useAccountingRegion } from "components/accounting-region-selector/hooks";
import { userUpdated } from "./slice";
import { IAuthZProps } from "./interfaces";
import { useGetUserAuthorizedPermissionsQuery } from "./endpoint";

/**
 * Authorization component to render children after confirming the user permissions
 */
const AuthZ: React.FC<IAuthZProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { updateRegion } = useAccountingRegion();

  const { isError, error, isFetching, data } =
    useGetUserAuthorizedPermissionsQuery({});
  React.useEffect(() => {
    if (data) {
      dispatch(userUpdated(data));
      if (data?.authorisedAccountingRegions?.length === 1) {
        updateRegion(data.authorisedAccountingRegions[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data), dispatch]);

  return isFetching || isError ? (
    <ApiIndicator
      isLoading={isFetching}
      isError={isError}
      isAlertError={true}
      error={error}
    />
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );
};

export default AuthZ;
