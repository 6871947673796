import { useAppSelector } from "app/store";
import { Outlet, useLocation } from "react-router-dom";
import Unathorized from "pages/unathorized";
import { isUserAuthorized } from "./utils";

export const RouteGuard = () => {
  let location = useLocation();
  const { user } = useAppSelector((state) => state.auth);
  const auth = isUserAuthorized(
    location.pathname,
    user?.authorisedPages ?? null
  );
  return auth ? <Outlet /> : <Unathorized></Unathorized>;
};
