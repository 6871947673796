import { createLabelValueObject } from "utils/utils";
import { DEFAULT_SUBCATEGORY_MAPPING } from "./constants";
import { CategorySubcategoryData } from "./interfaces";

/**
 * Function to get the list of categories
 * @param categorySubcategoryObj - category to subcategory data
 * @returns - category[]
 */
export const getCategories = (
  categorySubcategoryObj: CategorySubcategoryData
) => {
  var categories = [];
  for (const key in categorySubcategoryObj) {
    categories.push(createLabelValueObject(key));
  }
  return categories;
};

/**
 * Function to get the list of subcategories from the given category
 * @param categorySubcategoryObj - category to subcategory data
 * @param selectedCategory - category
 * @returns - subcategory[]
 */
export const getSubcategories = (
  categorySubcategoryObj: CategorySubcategoryData,
  selectedCategory?: string
) => {
  const subcategories = [];
  if (selectedCategory && categorySubcategoryObj[selectedCategory]) {
    subcategories.push(DEFAULT_SUBCATEGORY_MAPPING);
    for (const subcategory of categorySubcategoryObj[selectedCategory]) {
      subcategories.push(createLabelValueObject(subcategory));
    }
  }
  return subcategories;
};

export const updateEffectiveDate = (effectiveDate: string) => {
  const parts = effectiveDate.split("-"); // Split the string into parts
  // Ensure there are two parts (month and year)
  if (parts.length === 2) {
    const month = parts[0];
    const year = parts[1];
    const newDate = `01-${month}-${year}`;
    return newDate;
  } else {
    console.log("Invalid date format");
    return "";
  }
};
