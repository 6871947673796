import { IAuthorisedPagesProps } from "features/auth/interfaces";
/**
 * Function to check if the user is authorized to acces the pathName to or not
 * @param authorisedPages map of page & autorization
 * @param pathName requested location by the user
 * @returns true or false
 */
export const isUserAuthorized = (
  pathName: string,
  authorisedPages: IAuthorisedPagesProps | null
) => {
  if (!pathName || !authorisedPages) return null;
  if (pathName === "/") {
    return authorisedPages["home"];
  }
  if (pathName.startsWith("/approvals")) {
    return authorisedPages["approvals"];
  }
  pathName = pathName.slice(1); //Removing the first character('/') from the pathName
  return !!authorisedPages[pathName as keyof IAuthorisedPagesProps];
};
