export const AllTableColumns = [
  "companyCode",
  "accountNumber",
  "transactionType",
  "exceptions",
  "payor",
  "payee",
  "jeSource",
  "lineDescription",
  "enteredCurrency",
  "enteredAmount",
  "agreementLineItem",
  "icTransactionId",
  "transactionKey",
  "jeBatchId",
  "jeHeaderId",
];
export const PREFERENCES_STORAGE_KEY = "AmbiguousExceptions-Table-Preferences";
export const COLUMN_WIDTHS_STORAGE_KEY =
  "AmbiguousExceptions-Table-ColumnWidths";
export const TOGGLE_VIEW_STORAGE_KEY = "AmbiguousExceptions-View-Preferences";
export const TOP_DOWN_VIEW = true;
