import { LinkProps } from "@amzn/awsui-components-react/polaris/link";
import { infoOpened, infoUpdated } from "./slice";
import { useAppDispatch, useAppSelector } from "app/store";

export const useInfo = () => {
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.info);

  return {
    info: selector,
    updateInfo: (payload: React.ReactNode) => dispatch(infoUpdated(payload)),
    setOpenInfo: (payload: boolean) => dispatch(infoOpened(payload)),
  };
};

/**
 * Hook for handling side navigation events
 */
export const useOnFollowHandler = () => {
  const { setOpenInfo } = useInfo();

  return (event: CustomEvent<LinkProps.FollowDetail>) => {
    if (!event.detail.external) {
      event.preventDefault();
      setOpenInfo(true);
    }
  };
};
