import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Period } from "./interfaces";

const initialState: Period = {};

export const periodStateSlice = createSlice({
  name: "PeriodState",
  initialState,
  reducers: {
    periodStateUpdate: (state: Period, action: PayloadAction<Period>) => {
      state.label = action.payload.label;
      state.value = action.payload.value;
    },
  },
});

export const { periodStateUpdate } = periodStateSlice.actions;

export default periodStateSlice.reducer;
