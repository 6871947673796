import { useAppDispatch, useAppSelector } from "app/store";
import { regionStateUpdate } from "./slice";

export const useAccountingRegion = () => {
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.accountingRegion);

  return {
    region: selector,
    updateRegion: (payload: string) => dispatch(regionStateUpdate(payload)),
  };
};
