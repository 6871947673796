import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { INotification, INotificationsState } from "./interfaces";

const initialState: INotificationsState = {
  items: [],
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    notificationAdded: (state, action: PayloadAction<INotification>) => {
      state.items.push(action.payload);
    },
    notificationDismissed: (state, action: PayloadAction<string | number>) => {
      state.items.splice(Number(action.payload), 1);
    },
    notificationsCleared: (state) => {
      state.items = [];
    },
  },
});

export const {
  notificationAdded,
  notificationDismissed,
  notificationsCleared,
} = notificationsSlice.actions;

export const selectNotifications = (state: RootState) => state.notifications;

export default notificationsSlice.reducer;
