import { AwsRum, AwsRumConfig } from "aws-rum-web";
import { useGetSettingsQuery } from "features/settings";

/**
 * Initializes a RUM web client on the client side.
 * This will capture all the data/metrics and send it to RUM servers.
 */
export const useRum = () => {
  const { data } = useGetSettingsQuery();
  try {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      guestRoleArn: data?.analytics.unauthenticatedRoleARN,
      identityPoolId: data?.analytics.identityPoolId,
      endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
      telemetries: ["errors", "performance", "http"],
      allowCookies: true,
      enableXRay: true,
    };

    const APPLICATION_ID: string = data?.analytics.cfnAppMonitorId;
    const APPLICATION_VERSION: string = "1.0.0";
    const APPLICATION_REGION: string = data?.auth.region;

    new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
  } catch (error) {
    console.error(error);
  }
};
